<template>
  <div class="error-page">
    <b-container>
      <div class="errorContainer mx-auto">
        <h1 class="errorCode">403</h1>
        <p class="errorInfo">
          Oops, you are not allowed to access this page!
        </p>
        <p class="errorHelp mb-3">
          Please go back and try again.
        </p>
        <p>
          <router-link :to="{ name: 'home' }">
            <b-button class="mr-xs" variant="default">
              back
            </b-button>
          </router-link>
        </p>
      </div>
      <footer class="pageFooter">{{ year }} &copy; Q Power Solution GmbH</footer>
    </b-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Page403',
  computed: {
    year() {
      return dayjs().format('YYYY');
    },
  },
};
</script>

<style src="./Error.scss" lang="scss" scoped />
